body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
.App {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  user-select: none;
  overscroll-behavior: none;
}

strong,
ul,
li,
em,
p {
  -webkit-user-select: text;
  user-select: text;
}

.scroll-animation {
  transition: scrollTop 0.3s ease-in-out;
}

p {
  margin: 0;
}

.pac-container {
  border: solid 1px #e0e0e0;
  box-shadow: none !important;
  z-index: 99999999999 !important;
}

.toolbar {
  z-index: 10000;
  height: 40px;
  position: sticky;
  top: -60px;
  margin-bottom: 35px;
}

@media (max-width: 600px) {
  .toolbar {
    top: -30px;
  }
}

.toolbar-item {
  display: flex;
  align-items: center;
  justify-content: center;
}


.toolbar i.chevron-down.inside {
  margin-top: 9px;
}

.editor-shell {
  max-width: 50000px;
}