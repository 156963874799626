.character {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-size: '2px';
    font-weight: 600;
    color: #000;
    background-color: #fff;
    transition: all 0.3s;
    border-radius: 8px;
    border: 1px solid #999DA0;
}